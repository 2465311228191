import { Slide } from '@mui/material';
import NavLogo from './NavLogo';
import NavigationItem from './NavigationItem';
import { BottomNavigationProps } from './types';

const BottomNavigations = (props: BottomNavigationProps) => {
  const {
    navLists,
    className,
    logo,
    onClickLogo,
    expanded,
    onExpand,
    isLogoSelected,
    bottomSection,
    ...restProps
  } = props;

  const finalClassName = [
    'fixed bottom-0 left-0 px-4 pt-2 pb-4 w-full shadow-bottom_navigations bg-white z-10 h-[64px] max-w-[100dvw] ',
    className,
  ].join(' ');

  return (
    <Slide direction="up" in={true}>
      <nav {...restProps} className={finalClassName}>
        {navLists.map((item) => {
          return (
            <div
              key={item.id}
              className="flex flex-row items-center justify-between"
            >
              {item.navItems.map((navItem) =>
                navItem.renderChild ? (
                  navItem.renderChild()
                ) : (
                  <NavigationItem key={navItem.id} navItem={navItem} />
                )
              )}
              <NavLogo
                logo={logo}
                onClick={onClickLogo}
                isLogoSelected={isLogoSelected}
              />
            </div>
          );
        })}
      </nav>
    </Slide>
  );
};

export default BottomNavigations;
